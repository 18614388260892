import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { grey } from '@material-ui/core/colors'
import withStyles from '@material-ui/core/styles/withStyles'
import Fab from '@material-ui/core/Fab'
import Paper from '@material-ui/core/Paper'
import ArrowBackIcon from '@material-ui/icons/KeyboardArrowLeft'
import ArrowForwardIcon from '@material-ui/icons/KeyboardArrowRight'
import classNames from 'classnames'
import SwipableCarousel from './SwipableCarouselView'
import { modulo } from '../utils/helpers'
import Dots from './CarouselDots'

import { css } from '@emotion/core'
import { mq } from './styles/Layout.styles'

const arrow = css({
  width: '40px!important',
  height: '40px!important',
  position: 'absolute!important',
  top: 'calc((100% - 170px) / 2 + 24px)',
  backgroundColor: 'white!important'
})

const arrowLeft = css(
  mq({
    left: [-64, -64, -96]
  })
)

const arrowRight = css(
  mq({
    right: [-64, -64, -96]
  })
)

const styles = {
  root: {
    '& > *:focus': {
      outline: 'none'
    }
  },
  content: {
    width: '100%',
    position: 'relative'
  },
  contentMobile: {
    width: '100%',
    height: '100%',
    maxWidth: 'initial',
    maxHeight: 'initial',
    margin: 0,
    top: 0,
    transform: 'none',

    '& > $carouselWrapper': {
      borderRadius: 0
    }
  },
  arrowIcon: {
    color: grey[700]
  },
  carouselWrapper: {
    overflow: 'hidden',
    transform: 'scale(1.0)',
    background: 'transparent',
    height: '100%'
  },
  dots: {
    paddingTop: 0,
    margin: '0 auto'
  },
  dotsMobile: {
    paddingTop: 0
  },
  dotsMobileLandscape: {
    paddingTop: 20
  },
  footer: {
    width: '100%',
    position: 'relative',
    textAlign: 'center'
  },
  footerMobile: {},
  footerMobileLandscape: {
    display: 'inline-block',
    width: 'auto'
  },
  slide: {
    width: '100%',
    height: '100%'
  },
  slideMobile: {
    width: '100%',
    height: '100%'
  },
  carousel: {
    height: '100%'
  },
  carouselContainer: {
    height: '100%'
  },
  closed: {}
}

class Carousel extends Component {
  state = {
    slideIndex: 0
  }

  handleContentClick = (e) => e.stopPropagation() || e.preventDefault()

  handleChange = (slideIndex) => {
    this.setState({
      slideIndex
    }, this.onChange(slideIndex))
  }

  decreaseIndex () {
    const slideIndex = this.state.slideIndex - 1
    this.setState({
      slideIndex
    }, this.onChange(slideIndex))
  }

  increaseIndex () {
    const slideIndex = this.state.slideIndex + 1
    this.setState({
      slideIndex
    }, this.onChange(slideIndex))
  }

  onChange (slideIndex) {
    if (this.props.onChange) {
      this.props.onChange(modulo(slideIndex, this.props.children.length))
    }
  }

  render () {
    const {
      autoplay,
      children,
      classes,
      containerStyle,
      hideArrows,
      interval,
      landscape: landscapeProp,
      mobile,
      open,
      mobileDots,
      hideDots
    } = this.props
    const landscape = mobile && landscapeProp
    const hasMultipleChildren = children.length !== null
    const childrenLength = React.Children.count(children)

    const carousel = (
      <SwipableCarousel
        autoplay={open && autoplay && hasMultipleChildren}
        className={classes.carousel}
        containerStyle={{ height: '100%', ...containerStyle }}
        index={this.state.slideIndex}
        interval={interval}
        onChangeIndex={this.handleChange}
        slideClassName={classes.slide}
      >
        {
          React.Children.map(children, c => React.cloneElement(c, {
            mobile,
            landscape
          }))
        }
      </SwipableCarousel>
    )

    return (
      <div
        className={classNames(classes.content, {
          [classes.contentMobile]: mobile
        })}
        onClick={this.handleContentClick}
      >
        <div>
          <Paper
            elevation={0}
            className={classes.carouselWrapper}>
            {carousel}
          </Paper>
          <div
            className={classNames(classes.footer, {
              [classes.footerMobile]: mobile,
              [classes.footerMobileLandscape]: landscape
            })}
          >
            {
              hasMultipleChildren && !hideDots &&
              <Dots
                count={childrenLength}
                index={modulo(this.state.slideIndex, childrenLength)}
                className={classNames(classes.dots, {
                  [classes.dotsMobile]: mobile,
                  [classes.dotsMobileLandscape]: landscape
                })}
                onDotClick={this.handleChange}
                mobile={mobileDots}
              />
            }
          </div>
        </div>
        {!hideArrows && hasMultipleChildren && (
          <>
            <Fab
              css={[arrow, arrowLeft]}
              onClick={() => this.decreaseIndex()}
            >
              <ArrowBackIcon className={classes.arrowIcon} />
            </Fab>
            <Fab
              css={[arrow, arrowRight]}
              onClick={() => this.increaseIndex()}
            >
              <ArrowForwardIcon className={classes.arrowIcon} />
            </Fab>
          </>
        )}
      </div>
    )
  }
}

Carousel.defaultProps = {
  autoplay: true,
  interval: 15000,
  mobile: false,
  open: false,
  hideArrows: false,
  hideDots: false
}

Carousel.propTypes = {
  /** If `false`, the auto play behavior is disabled. */
  autoplay: PropTypes.bool,
  /** Object for customizing the CSS classes. */
  classes: PropTypes.object.isRequired,
  /** Override the inline-styles of the carousel container. */
  containerStyle: PropTypes.object,
  /** Delay between auto play transitions (in ms). */
  interval: PropTypes.number,
  /** If `true`, slide will adjust content for wide mobile screens. */
  landscape: PropTypes.bool,
  /** If `true`, the screen width and height is filled. */
  mobile: PropTypes.bool,
  /** Fired when the index changed. Returns current index. */
  onChange: PropTypes.func,
  /** Controls whether the Carousel is opened or not. */
  open: PropTypes.bool,
  /** If `true`, the left and right arrows are hidden in the desktop version. */
  hideArrows: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.object
  ]),
  mobileDots: PropTypes.bool,
  hideDots: PropTypes.bool
}

export default withStyles(styles)(Carousel)