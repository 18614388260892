import React from 'react'
import { css } from '@emotion/core'
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/styles'

import ds from '../theme'
import { mq } from './styles/Layout.styles';
import Div from './Div/Div.styles';
import H5 from './H5';
import Separator from './Separator';
import H3 from './H3';
import Subtitle from './SubTitle';

const MuiSlider = withStyles({
  root: {
    color: '#e64b00',
  },
  thumb: {
    backgroundColor: '#e64b00',
  },
  rail: {
    backgroundColor: '#FBE5DA'
  },
  mark: {
    backgroundColor: '#e64b00'
  }
})(Slider);

const ConfigureRangeSlider = ({
  title,
  unit,
  price,
  style,
  max,
  step,
  min,
  normalizeValue,
  field,
  form
}) => {
  const { value, name } = field

  const valuetext = (ariaValue) => {
    return `${ariaValue}${unit}`;
  }

  const unitValue = () => {
    return normalizeValue
      ? normalizeValue(value)
      : value
  }

  return (
    <div
      style={{
        padding: 24,
        backgroundColor: '#ffffff',
        borderRadius: 12,
        ...style
      }}
      css={
        css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        )
      }
    >
      <Div
        flexDirection='column'
        alignItems='center'
      >
        <H5
          color='black'
          variant='highEmphasis'
          align='center'
          mb={[4]}
          style={{
            width: 296
          }}
        >
          {title}
        </H5>
        <Separator
          style={{
            marginBottom: 24
          }}
        />
        <H3
          color='secondary'
          align='center'
          mb={[4]}
        >
          {unitValue()}{unit}
        </H3>
        <Separator
          style={{
            marginBottom: 24
          }}
        />
        <Div
          maxWidth={[280]}
        >
          <MuiSlider
            getAriaValueText={valuetext}
            step={step}
            marks
            value={value}
            onChange={(e, newValue) => form.setFieldValue(name, newValue)}
            min={min}
            max={max}
          />
        </Div>
        <Div
          mt={[4]}
        >
          <Subtitle
            color='secondary'
            align='center'
          >
            R$ {(price * value).toFixed(2)}
          </Subtitle>
        </Div>
      </Div>
    </div>
  )
}

ConfigureRangeSlider.defaultProps = {
  initialValue: 1,
  max: 10,
  min: 1,
  step: 1,
  unit: ''
}

export default ConfigureRangeSlider;