import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledCloudServerBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [14, 14, 34, 34]
  })
)

export const styledCloudServer = css`
  ${styledCloudServerBps};
  h5 {
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    margin-bottom: 16px;
  }
`

export const H4HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    letterSpacing: [0.25],
    textAlign: ['center']
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    textAlign: ['center']
  })
)

export const PBps = css(
  mq({
    textAlign: ['center']
  })
)

export const PTableBps = css(
  mq({
    width: [130, 328],
    textAlign: ['center']
  })
)

export const spanBps = css(
  mq({
    textAlign: ['center']
  })
)

export const imgBps = css(
  mq({
    maxWidth: [300, 340, 350, 350],
    height: [223, 253, 260, 260]
  })
)

export const highlightBoxBps = css(
  mq({
    width: [328, 422, 680, 680],
    marginLeft: [16, 89, 200, 429],
    marginRight: [16, 89, 200, 429],
    paddingLeft: [16, 63, 32, 32],
    paddingRight: [16, 63, 32, 32],
    paddingTop: [50],
    paddingBottom: [34]
  })
)

export const tableBps = css(
  mq({
    width: [328, '100%', 680, 680],
    marginLeft: [16, 0, 200, 429],
    marginRight: [16, 0, 200, 429],
    paddingTop: [50],
    paddingBottom: [34]
  })
)

export const header = css`
  display: flex;
  text-align: center;
  span {
    ${spanBps};
    text-transform: uppercase;
    font-size: 11.8px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    ${PHeaderBps};
  }
  img {
    ${imgBps};
    margin-top: 30px;
    margin-bottom: 30px;
  }
`

export const highlightBox = css`
  ${highlightBoxBps};
  margin: 0 auto !important;
  background: #9013fe;
  border-radius: 16px;
`

export const circle = css`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #f3f5f5;
  border: 2px solid #fff;
  box-shadow: 0 0 0 4px #f3f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 23.7px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
`
export const stepsCloud = css`
  h5 {
    color: rgba(0, 0, 0, 0.87);
    margin-top: 16px;
    margin-bottom: 24px;
  }
  img {
    margin-bottom: 24px;
    max-width: 323px;
  }
  p {
    ${PBps};
  }
`
export const steptCloudItem = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const listCloudItem = css`
  display: flex;
  align-items: end;
  margin-bottom: 16px;
  img {
    margin-right: 16px;
  }
  span {
    font-size: 15.8px;
    font-weight: 500;
    line-height: 1.77;
    letter-spacing: 0.5px;
    color: #f9fafa;
  }
`
export const faqCloudServer = css`
  background-color: #f3f5f5;
  display: flex;
  justify-content: center;
  h4 {
    color: rgba(0, 0, 0, 0.87);
  }
`

export const table = css`
  ${tableBps};
  margin: 0 auto !important;
  border-radius: 16px;
  p {
    ${PTableBps};
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
  }
`

export const iconeX = css`
  font-size: 11.8px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.4px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  padding-left: 30px;
  padding-right: 30px;
`