import React from 'react'

const Separator = ({
  style
}) => (
  <div
    style={{
      width: 50,
      height: 4,
      backgroundColor: '#f3f5f5',
      borderRadius: 2,
      ...style
    }}
  />
)

export default Separator;