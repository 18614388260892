import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiRadio from '@material-ui/core/Radio'
import { makeStyles } from '@material-ui/core/styles'

import Body2 from './Body2';

const useStyles = makeStyles(theme => ({
  colorPrimary: {
    color: '#e64b00',
    '&$checked': {
      color: '#e64b00',
    },
    marginRight: 9,
    marginLeft: -12
  },
  label: {
    fontWeight: '600!important',
    fontSize: '15.7px!important'
  }
}))

const Radio = ({
  label,
  style,
  subLabel,
  field: {
    name,
    onBlur,
    value: fieldValue
  },
  form: {
    setFieldValue
  },
  value,
  rawValue,
  onChange
}) => {
  const classes = useStyles()

  return (
    <div>
      <FormControlLabel
        control={(
          <MuiRadio
            color='default'
            classes={{
              root: classes.colorPrimary
            }}
            checked={fieldValue === value}
            value={value}
            onChange={(e) => {
              setFieldValue(name, rawValue ? e.target.value : Number(e.target.value))
              if (onChange) {
                onChange()
              }
            }}
            onBlur={onBlur}
            name={name}
          />
        )}
        label={(
          <>
            {label}
            {subLabel && (
              <Body2
                color='black'
                variant='mediumEmphasis'
                style={{
                  fontWeight: 'normal'
                }}
              >
                {subLabel}
              </Body2>
            )}
          </>
        )}
        labelPlacement='end'
        style={{
          textTransform: 'normal',
          marginLeft: 0,
          marginRight: 0,
          ...style
        }}
        classes={{
          label: classes.label
        }}
      />
    </div>
  )
}

Radio.defaultProps = {
  field: {},
  form: {},
  rawValue: false
}

export default Radio
