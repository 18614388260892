import React, { Fragment, useState, useContext } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, navigate }  from 'gatsby'
import { Field, withFormik } from 'formik'
import compose from 'lodash/fp/flowRight'
import ArrowR from '@material-ui/icons/ArrowForward'
import ThumbUp from '@material-ui/icons/ThumbUpAlt'
import ThumbDown from '@material-ui/icons/ThumbDownAlt'
import UnderstandTheDifference from '../components/UnderstandTheDifference'
import Carousel from '../components/Carousel'
import { useMediaQuery } from 'react-responsive'

import Layout from '../components/Layout'
import ds from '../theme'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div'

import Button from '../components/Button/Button'
import MuiSelect from '../components/Dropdown'
import Hr from '../components/Hr'
import H3 from '../components/H3'
import H4 from '../components/H4'
import H5 from '../components/H5'
import Paragraph from '../components/Paragraph'
import Subtitle1 from '../components/SubTitle'
import Subtitle from '../components/SubTitle2'
import Body1 from './../components/Body1'
import Body2 from './../components/Body2'
import ImgCloudServer from '../images/cloud-server/cloud-server.png'
import ImageStepCloud1 from '../images/cloud-server/img-step-1@3x.png'
import ImageStepCloud2 from '../images/cloud-server/img-step-2@3x.png'
import ImageStepCloud3 from '../images/cloud-server/img-step-3@3x.png'
import ArrowDown from '../images/arrow-down-24-px.png'
import ArrowRight from '../images/arrow-right-24-px.png'

import { Container, mq } from '../components/styles/Layout.styles'
import { css } from '@emotion/core'
import ConfigureCloud from '../components/ConfigureRangeSlider'
import Radio from '../components/Radio'
import Separator from './../components/Separator'
import { withSolutions, SolutionContext } from '../components/SolutionProvider'
import ScrollAnim from 'rc-scroll-anim'

import { 
  styledCloudServer,
  header,
  highlightBox,
  stepsCloud,
  steptCloudItem,
  circle,
  listCloudItem,
  faqCloudServer,
  table,
  iconeX
} from '../components/styles/CloudServer.styles'
import { currency } from '../components/styles/DedicatedServersPrice.styles'
import CloudServerForm from '../components/ContactForm'

const contentStepsCloudServer = [
  {
    stepNumber: '1',
    titleStep: 'Criar seu cadastro',
    imgStep: ImageStepCloud1,
    contentStep: 'O Cloud Server Hostdime só pode ser acessado a partir da nossa área de cliente CORE. Você pode criar um perfil simples com informações básicas de contato ou um completo, para um suporte ainda mais personalizado. Também poderá adicionar contatos autorizados para acessar sua conta.'    
  },
  {
    stepNumber: '2',
    titleStep: 'Ativar conta',
    imgStep: ImageStepCloud2,
    contentStep: 'O serviço funciona por dedução de crédito calculada pelo uso/hora do serviço Cloud. Após confirmar seu e-mail, acesse a área do cliente CORE e adicione a partir de R$75 (valor mínimo) para elevar seu potencial para as nuvens. Aceitamos pagamentos em boleto, cartão de crédito, Paypal e Bitcoin.'    
  },
  {
    stepNumber: '3',
    titleStep: 'Comece a usar!',
    imgStep: ImageStepCloud3,
    contentStep: 'Através de nossa plataforma moderna e intuitiva, você poderá gerenciar a sua nuvem em tempo real, aumentando ou diminuindo seus ativos computacionais de acordo com a sua demanda. Alta disponibilidade e segurança pagando apenas pelo que usar, quando usar.'    
  }
]

const StepsCloudServerItem = ({
  stepNumber,
  titleStep,
  imgStep,
  contentStep,
}) => {
  return (
    <div>
      <Div
        flexDirection='column'
        alignItems='center'
      >
        <div
          css={circle}
          style={{
            marginTop: 12
          }}
        >
          {stepNumber}
        </div>
        <H5
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            marginBottom: 24
          }}
        >
          {titleStep}
        </H5>
        <div>
          <img src={imgStep} />
        </div>
        <Body2 
          style={{ 
            textAlign: 'center',
            lineHeight: 1.71,
            color: 'rgba(0, 0, 0, 0.6)'
          }}>
          {contentStep}
        </Body2>
      </Div>
    </div>
  )
}

const StepsCloudServer = ({
  items,
}) => {
  const matches = useMediaQuery({ query: '(min-width: 840px)' })
  const maxItems = matches ? 1 : 1
  const pages = Math.ceil(items.length / maxItems)
  return (
    <Fragment>
      <Div
        display={['none', 'none', 'flex', 'flex']}
      >
        <Carousel
          open
          mobileDots={!matches}
          landscape={0} 
          autoplay={false}
        >
          {Array(pages).fill(0).map((item, i) => i).map((page, i) => {
            const min = (i * maxItems)
            const max = (i * maxItems) + maxItems
            return (
              <Row
                justifyContent={'center'}
                flexDirection={['column', 'column', 'row', 'row']}
                alignItems={['center', 'center', 'flex-start']}
                key={`carouselpage-${i}`}
                style={{
                  margin: 0
                }}
              >
                {items.slice(min, max).map((item, i) => ( 
                  <Column
                    mb={[16, 16, 16, 0]}
                  >
                    <Div
                      maxWidth={['100%', 680]}
                    >
                      <StepsCloudServerItem
                        key={`carouselitem-${i}`}
                        stepNumber={item.stepNumber}
                        titleStep={item.titleStep}
                        imgStep={item.imgStep}
                        contentStep={item.contentStep}
                      />
                    </Div>
                  </Column>
                ))}
              </Row>
            )
          })}
        </Carousel>
      </Div>
      <Div
        display={['block', 'block', 'none', 'none']}
      >
        <Carousel
          open
          hideArrows
          mobileDots={!matches}
          landscape={0}
          autoplay={false} 
        >
          {Array(pages).fill(0).map((item, i) => i).map((page, i) => {
            const min = (i * maxItems)
            const max = (i * maxItems) + maxItems
            return (
              <Row
                justifyContent={'center'}
                flexDirection={['column', 'column', 'row', 'row']}
                alignItems={['center', 'center', 'flex-start']}
                key={`carouselpage-${i}`}
                style={{
                  margin: 0
                }}
              >
                {items.slice(min, max).map((item, i) => ( 
                  <Column
                    mb={[16, 16, 16, 0]}
                  >
                    <Div
                      maxWidth={['100%', 680]}
                    >
                      <StepsCloudServerItem
                        key={`carouselitem-${i}`}
                        stepNumber={item.stepNumber}
                        titleStep={item.titleStep}
                        imgStep={item.imgStep}
                        contentStep={item.contentStep}
                      />
                    </Div>
                  </Column>
                ))}
              </Row>
            )
          })}
        </Carousel>
      </Div>
    </Fragment>
  )
}

const fields = [
  {
    label: 'Nome Completo',
    name: 'your-name',
    type: 'text',
    required: true
  },
  {
    label: 'Email',
    name: 'your-email',
    type: 'email',
    required: true
  },
  {
    label: 'Empresa',
    name: 'your-company',
    type: 'text',
    required: true
  },
  {
    label: 'CNPJ',
    name: 'your-cnpj',
    type: 'text',
    required: true
  },
  {
    label: 'Telefone',
    name: 'your-phone',
    type: 'text',
    required: true,
    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  }
]

const initialModals = {
  support: false,
  moreInfo: false
}

const panelsDescs = [
  {
    title: 'Sem Painel de Controle:',
    description: 'Sem painel de controle.'
  },
  {
    title: 'Painel cPanel:',
    description: 'Famoso no mercado.'
  },
  {
    title: 'Painel de Plesk:',
    description: 'Simplifica o gerenciamento da infraestrutura cloud e torna a criação e execução de websites e aplicações mais fácil para iniciantes e experientes. Use o Plesk para administrar seu Cloud Server.'
  }
]

const SelectedDesc = ({
  title,
  description
}) => {
  return (
    <Div>
      <Subtitle
        color='black'
        variant='mediumEmphasis'
        mb={[2]}
      >
        {title}
      </Subtitle>
      <Body2
        color='black'
        variant='mediumEmphasis'
      >
        {description}
      </Body2>
    </Div>
  )
}

const Solution = ({
  title,
  children,
  id,
}) => {
  const [active, setIsActive] = useContext(SolutionContext)
  const isActive = active === id
  return (
    <div
      onClick={() => setIsActive(id)}
      style={{
        padding: 24,
        backgroundColor: '#FFFFFF',
        borderRadius: 16,
        transition: 'height 0.2s',
        cursor: 'default'
      }}
      css={
        css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        )
      }
    >
      <Div
        flexDirection='row'
        alignItems='center'
      >
        <div
          style={{
            width: 24,
            height: 24,
            backgroundImage: isActive ? `url(${ArrowDown})` : `url(${ArrowRight})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            marginRight: ds.space(3)
          }}
        />
        <Body1
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            color: 'rgba(0, 0, 0, 0.87)',
            lineHeight: 1.59,
            fontSize: 13.8,
            fontWeight: 600
          }}
        >
          {title}
        </Body1>
      </Div>
      {children && (
        <Div
          style={{
            height: isActive ? 'auto' : 0,
            opacity: isActive ? 1 : 0,
            transition: isActive ? 'height 0.2s, opacity 0.2s 0.1s' : 'height 0.2s 0.1s, opacity 0.2s',
            zIndex: isActive ? 1 : -1,
            position: 'relative'
          }}
        >
          <Body2
            color='black'
            variant='mediumEmphasis'
            mt={[3]}
          >
            {children}
          </Body2>
        </Div>
      )}
    </div>
  )
}

const dcPrices = {
  usa: {
    vcpu: 35.40,
    ssd: 0.60,
    initialBandwidth: 0,
    bandwidth: 40.96,
    backup: 0.20,
    ram: 8.00,
    ip: 20.00,
    controlPanel: {
      none: 0,
      plesk: 60,
      cpanel: 74.99,
      cpanelPremier: 249
    },
    os: {
      centos: 0,
      cloudlinux: 50,
      debian: 0,
      ubuntu: 0,
      win2012: 108,
      win2016: 108
    }
  },
  ne: {
    vcpu: 46.02,
    ssd: 0.78,
    initialBandwidth: 0,
    bandwidth: 40.96,
    backup: 0.28,
    ram: 10.40,
    ip: 26,
    controlPanel: {
      none: 0,
      plesk: 60,
      cpanel: 74.99,
      cpanelPremier: 249
    },
    os: {
      centos: 0,
      cloudlinux: 50,
      debian: 0,
      ubuntu: 0,
      win2012: 108,
      win2016: 108
    }
  }
}

const calculateTotal = ({ prices, cloudServer, bandwidth, controlPanel, os }) => {
  const currentPrices = dcPrices[cloudServer]
  let total = Object.keys(prices).map((key) => currentPrices[key] * prices[key]).reduce((acc, curr) => acc + curr, 0)
  if (bandwidth !== 10) {
    total = total + currentPrices.bandwidth * bandwidth
  }
  total = total + currentPrices.controlPanel[controlPanel]
  total = total + currentPrices.os[os]

  return total
}

const PriceForm = ({
  values,
  setModalVisibility
}) => {
  const total = calculateTotal(values)
  return (
    <>
      <Row
        flexDirection={['column']}
        pt={50}
      >
        <Column
          width={[1]}
          mb={30}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <H5
              style={{
                color: 'rgba(0, 0, 0, 0.87)',
                marginBottom: 24,
                textAlign: 'center'
              }}
            >
              Customize sua Cloud
            </H5>
            <Field
              name='cloudServer'
              render={({ field, form }) => {
                const options = [
                  { label: 'DC Orlando - USA', value: 'usa' },
                  { label: 'DC Nordeste - Brasil', value: 'ne' }
                ]

                return (
                  <MuiSelect
                    options={options}
                    label='DC Nordeste - Brasil'
                    value={options.find(option => option.value === field.value)}
                    onChange={(value) => {
                      form.setFieldValue(field.name, value.value)
                    }}
                    isClearable={false}
                    style={{
                      maxWidth: 328,
                      marginBottom: 20
                    }}
                  />
                )
              }}
            />
            <Subtitle
              maxWidth={[200, '100%']}
              style={{
                color: 'rgba(0, 0, 0, 0.6)',
                textAlign: 'center'
              }}
            >
              Brasil ou Estados Unidos? 
              <span
                onClick={() => setModalVisibility('moreInfo')(true)}
                style={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  marginLeft: 2,
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
              >
                  Entenda a diferença
              </span>
            </Subtitle>
          </div>
        </Column>            
      </Row>
      <Div
        alignItems={['center']}
        maxWidth={[328, 328, 328, 1032]}
        m={['0 auto 24px']}
      >
        <Row
          justifyContent={'center'}
          flexDirection={['column', 'column', 'column', 'row']}
          alignItems={['stretch']}
          style={{
            width: '100%'
          }}
        >
          <Column
            width={[1]}
            display={['flex']}
            style={{
              border: '1px solid rgb(243, 245, 245)',
              borderRadius: 12
            }}
            mb={[24, 24, 24, 0]}
          >
            <Div
              alignItems={['center']}
              p={[24]}
            >
              <H5
                color='black'
                variant='highEmphasis'
                mb={[4]}
              >
                Sistema Operacional
              </H5>
              <Separator
                style={{
                  marginBottom: 24
                }}
              />
              <Field
                name='os'
                render={({ field, form }) => {
                  const options = {
                    none: [
                      { label: 'CentOS 7', value: 'centos' },
                      { label: 'Debian', value: 'debian' },
                      { label: 'Ubuntu', value: 'ubuntu' },
                      { label: 'Windows Server 2012', value: 'win2012' },
                      { label: 'Windows Server 2016', value: 'win2016' }
                    ],
                    plesk: [
                      { label: 'CentOS 7', value: 'centos' },
                      { label: 'Windows Server 2012', value: 'win2012' },
                      { label: 'Windows Server 2016', value: 'win2016' }
                    ],
                    cpanel: [
                      { label: 'CentOS 7', value: 'centos' },
                      { label: 'CloudLinux 7', value: 'cloudlinux' }
                    ],
                    cpanelPremier: [
                      { label: 'CentOS 7', value: 'centos' },
                      { label: 'CloudLinux 7', value: 'cloudlinux' }
                    ]
                  }

                  return (
                    <MuiSelect
                      opaque
                      options={options[values.controlPanel]}
                      label='Sistema Operacional'
                      value={options[values.controlPanel].find(option => option.value === field.value)}
                      onChange={(value) => {
                        form.setFieldValue(field.name, value.value)
                      }}
                      isClearable={false}
                      style={{
                        maxWidth: 328,
                        marginBottom: 10
                      }}
                    />
                  )
                }}
              />
            </Div>
          </Column>
        </Row>
      </Div>
      <Row
        justifyContent={'center'}
        flexDirection={['column', 'column', 'column', 'row']}
        alignItems={'center'}
      >
        <Column
          mb={[30]}
        >
          <Div
            maxWidth={['100%', 328]}
            width={['100%']}
          >
            <Field
              name='prices.vcpu'
              component={ConfigureCloud}
              title='vCPU'
              max={16}
              normalizeValue={(value) => value.toString().padStart(2, '0')}
              price={dcPrices[values.cloudServer]['vcpu']}
              style={{
                border: '1px solid #f3f5f5'
              }}
            />
          </Div>
        </Column>
        <Column
          mb={[30]}
        >
          <Div
            maxWidth={['100%', 328]}
            width={['100%']}
          >
            <Field
              name='prices.ram'
              component={ConfigureCloud}
              title='RAM'
              unit='GB'
              max={64}
              price={dcPrices[values.cloudServer]['ram']}
              style={{
                border: '1px solid #f3f5f5'
              }}
            />
          </Div>
        </Column>
        <Column
          mb={[30]}
        >
          <Div
            maxWidth={['100%', 328]}
            width={['100%']}
          >
            <Field
              name='prices.ssd'
              component={ConfigureCloud}
              title='SSD Primary Storage'
              unit='GB'
              min={6}
              max={600}
              price={dcPrices[values.cloudServer]['ssd']}
              style={{
                border: '1px solid #f3f5f5'
              }}
            />
          </Div>
        </Column>
      </Row>
      <Row
        justifyContent={'center'}
        flexDirection={['column', 'column', 'column', 'row']}
        alignItems={'center'}
      >
        <Column
          mb={[30]}
        >
          <Div
            maxWidth={['100%', 328]}
            width={['100%']}
          >
            <Field
              name='bandwidth'
              component={ConfigureCloud}
              title='Bandwidth'
              unit='TB'
              min={10}
              max={100}
              price={values.bandwidth === 10
                ? dcPrices[values.cloudServer]['initialBandwidth']
                : dcPrices[values.cloudServer]['bandwidth']
              }
              style={{
                border: '1px solid #f3f5f5'
              }}
            />
          </Div>
        </Column>
        <Column
          mb={[30]}
        >
          <Div
            maxWidth={['100%', 328]}
            width={['100%']}
          >
            <Field
              name='prices.backup'
              component={ConfigureCloud}
              title='Backup Snapshot'
              unit='GB'
              price={dcPrices[values.cloudServer]['backup']}
              max={1000}
              style={{
                border: '1px solid #f3f5f5'
              }}
            />
          </Div>
        </Column>
        <Column
          mb={[30]}
        >
          <Div
            maxWidth={['100%', 328]}
            width={['100%']}
          >
            <Field
              name='prices.ip'
              component={ConfigureCloud}
              title='IPv4'
              price={dcPrices[values.cloudServer]['ip']}
              max={100}
              style={{
                border: '1px solid #f3f5f5'
              }}
            />
          </Div>
        </Column>
      </Row>
      <Div
        style={{
          border: '1px solid #E0E0E0',
          borderRadius: 12
        }}
        alignItems={['center']}
        maxWidth={[328, 328, 328, 1032]}
        m={['0 auto 24px']}
        p={[24, 24, 24, '24px 0']}
      >
        <H5
          color='black'
          variant='highEmphasis'
          mb={[4]}
        >
          Painel de Controle
        </H5>
        <Separator
          style={{
            marginBottom: 24
          }}
        />
        <Row
          justifyContent={'center'}
          flexDirection={['column', 'column', 'column', 'row']}
          alignItems={'center'}
          mb={[24]}
          style={{
            width: '100%'
          }}
        >
          <Column
            width={[1, 1, 1, 5/12]}
            display={['flex']}
            justifyContent={['center', 'center', 'center', 'flex-end']}
            mb={[21, 21, 21, 0]}
          >
            <Div
              alignItems={['center']}
              maxWidth={['100%', '100%', '100%', 240]}
            >
              <Div>
                <form>
                  <Field
                    name='controlPanel'
                    render={({ field, form }) => {
                      return (
                        <>
                          <Radio
                            label='Sem Painel de Controle'
                            subLabel='Sem Painel de Controle'
                            value='none'
                            style={{
                              marginBottom: 15
                            }}
                            field={field}
                            form={form}
                            rawValue
                            onChange={() => {
                              form.setFieldValue('os', 'centos')
                            }}
                          />
                          <Radio
                            label='cPanel - Solo (1 conta)'
                            subLabel='Total controle do seu Cloud Server Linux'
                            value='cpanel'
                            style={{
                              marginBottom: 15
                            }}
                            field={field}
                            form={form}
                            rawValue
                            onChange={() => {
                              form.setFieldValue('os', 'centos')
                            }}
                          />
                          <Radio
                            label='cPanel - Premier (Contas Ilimitadas)'
                            subLabel='Total controle do seu Cloud Server Linux'
                            value='cpanelPremier'
                            style={{
                              marginBottom: 15
                            }}
                            field={field}
                            form={form}
                            rawValue
                            onChange={() => {
                              form.setFieldValue('os', 'centos')
                            }}
                          />
                          <Radio
                            label='Plesk'
                            subLabel='Total controle do seu Cloud Server Linux ou Windows'
                            value='plesk'
                            style={{
                              marginBottom: 15
                            }}
                            field={field}
                            form={form}
                            rawValue
                            onChange={() => {
                              form.setFieldValue('os', 'centos')
                            }}
                          />
                        </>
                      )
                    }}
                  />
                </form>
              </Div>
            </Div>
          </Column>
          <Column
            width={[1, 1, 1, 5/12]}
            display={['flex']}
            justifyContent={['center']}
          >
            <Div
              alignItems={['center']}
              maxWidth={[305]}
            >
              <SelectedDesc
                {...panelsDescs[values.controlPanel]}
              />
            </Div>
          </Column>
        </Row>
      </Div>
      <Div
        style={{
          border: '1px solid #E0E0E0',
          borderRadius: 12
        }}
        alignItems={['center']}
        maxWidth={[328, 328, 328, 1032]}
        m={['0 auto 24px']}
        p={[24, 24, 24, '24px 0']}
      >
        <Row
          justifyContent={'center'}
          flexDirection={['column', 'column', 'column', 'row']}
          alignItems={'center'}
          style={{
            width: '100%'
          }}
        >
          <Column
            width={[1, 1, 1, 5/12]}
            display={['flex']}
            justifyContent={['center', 'center', 'center', 'flex-end']}
            mb={[21, 21, 21, 0]}
          >
            <Div
              alignItems={['center']}
              maxWidth={['100%', '100%', '100%', 240]}
            >
              <H5
                color='black'
                variant='highEmphasis'
                mb={[4]}
              >
                Custo Mensal
              </H5>
              <Separator
                style={{
                  marginBottom: 24
                }}
              />
              <Div
                alignItems={['center']}
              >
                <H3
                  selected
                  on='onSurface'
                  variant='highEmphasis'
                  style={{
                    letterSpacing: 0.25,
                    marginBottom: 16,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 296,
                    fontWeight: 500
                  }}
                >
                  <span css={currency}>R$</span>
                  {Math.trunc(total)}
                  <span css={currency}>,{/\.([0-9]+)/.exec(total.toFixed(2))[1]}</span>
                </H3>
                <Separator
                  style={{
                    marginBottom: 24
                  }}
                />
                {false && <Div
                  flexDirection={['row']}
                  justifyContent={['center']}
                >
                  <Paragraph 
                    style={{
                      fontSize: 15.8,
                      textAlign: 'left',
                      fontWeight: 500,
                      color: 'rgba(0, 0, 0, 0.87)',
                    }}>
                    Custo por Hora
                  </Paragraph>
                  <Body1 
                    style={{
                      fontSize: 15.8,
                      fontWeight: 600,
                      color: 'rgba(0, 0, 0, 0.87)',
                      marginLeft: 4
                    }}>
                    R$ 0,45380
                  </Body1>
                </Div>}
              </Div>
            </Div>
          </Column>
          <Column
            width={[1, 1, 1, 5/12]}
            display={['flex']}
            justifyContent={['center']}
          >
            <Div
              alignItems={['center']}
              maxWidth={[305]}
            >
              <Button
                onClick={() => navigate('/contatos')}
                schema='secondary'
                color='white'
                variant='highEmphasis'
                width={['100%']}
                maxWidth={['240px']}
                style={{
                  display: 'flex',
                  border: 'none',
                  margin: '0 auto',
                  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
                }}
              >
                CONSULTE
              </Button>
            </Div>
          </Column>
        </Row>
      </Div>
    </>
  )
}

const EnhancePriceForm = withFormik({
  mapPropsToValues: (props) => ({
    managed: 0,
    cloudServer: 'usa',
    controlPanel: 'none',
    os: 'centos',
    bandwidth: 10,
    prices: {
      vcpu: 1,
      ram: 1,
      ssd: 6,
      backup: 1,
      ip: 1
    }
  })
})(PriceForm)

export const PageTemplate = ({
  data,
  ...rest
}) => {
  const [modals, setModals] = useState(initialModals)

  const setModalVisibility = modal => visible => {
    setModals({
      ...modals,
      [modal]: visible
    })
  }
  return (
    <Fragment>
      <section css={styledCloudServer}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H4>
                <span>cloud server hostdime</span><br />
                Eleve seu potencial
              </H4>
              <img src={ImgCloudServer} />
              <Paragraph>Amplie todas as possibilidades do seu negócio através de uma plataforma moderna e intuitiva que permite a criação e o gerenciamento de nuvem em tempo real, pagando pelo que usar, apenas quando usar, como: máquinas virtuais, firewall, VPN, balanceadores de carga, entre outros.</Paragraph>
            </Column>
          </Row>
          <Row
            mt={30}
            mb={49.5}
          >
            <Div 
              flexDirection={['column', 'column', 'unset', 'unset']}
              css={highlightBox}>
              <Column
                width={[1, 1, 1/2, 1/2]}
              >
                <div css={listCloudItem}>
                  <ArrowR 
                    style={{
                      color: 'rgba(255, 255, 255, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>Otimização de Custos</span>
                </div>
                <div css={listCloudItem}>
                  <ArrowR 
                    style={{
                      color: 'rgba(255, 255, 255, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>Autonomia de Gerenciamento</span>
                </div>
                <div css={listCloudItem}>
                  <ArrowR 
                    style={{
                      color: 'rgba(255, 255, 255, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>Flexibilidade de Recursos</span>
                </div>
              </Column>
              <Column
                width={[1, 1, 1/2, 1/2]}
              >
                <div css={listCloudItem}>
                  <ArrowR 
                    style={{
                      color: 'rgba(255, 255, 255, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>Confiabilidade & Alta<br /> Disponibilidade</span>
                </div>
                <div css={listCloudItem}>
                  <ArrowR 
                    style={{
                      color: 'rgba(255, 255, 255, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>Segurança da Informação</span>
                </div>
              </Column>
            </Div>
          </Row>
          <Div 
            alignItems={['center']}
          >
            <Hr
              lineColor='lightPeriwinkle'
              m={['0']}
              maxWidth={[328, '100%' ,680 ,680]}
              style={{
                opacity: 0.5
              }}
            />
          </Div>
          <Row
            css={header}
            justifyContent={'center'}
            mt={[40, 45.5, 39.5, 39.5]}
            mb={18}
          >
            <Column>
              <H4 style={{ marginBottom: 16 }}>Utilizando o Cloud<br /> Server HostDime</H4>
              <Paragraph>Os seus primeiros passos com nossos <br />serviços em nuvem</Paragraph>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column']}
            css={stepsCloud}
            mb={30}
          >
            <Column
              width={[1]}
            >
              <Div
                maxWidth={['100%', '100%', 510]}
                alignItems={['center']}
                style={{
                  margin: '0 auto'
                }}
              >
                {contentStepsCloudServer.length <= 1
                ? (
                  <Row
                    justifyContent={'center'}
                    flexDirection={['column', 'column', 'row', 'row']}
                    alignItems={['center', 'center', 'flex-start']}
                  >
                    {contentStepsCloudServer.map((item) => (
                      <Column
                        mb={[16, 16, 16, 0]}
                      >
                        <Div
                          maxWidth={['100%', 328]}
                        >
                          <StepsCloudServerItem
                            stepNumber={item.stepNumber}
                            titleStep={item.titleStep}
                            imgStep={item.imgStep}
                            contentStep={item.contentStep}
                          />
                        </Div>
                      </Column>
                    ))}
                  </Row>
                )
                : (
                  <StepsCloudServer
                    items={contentStepsCloudServer}
                  />
                )}
              </Div>
            </Column>
          </Row>
          <Div
            css={steptCloudItem}
          >
            <ScrollAnim.Link
              to='quero-testar'
              offsetTop='120'
            >
              <Button
                schema='secondary'
                color='white'
                variant='highEmphasis'
                width={['200px']}
                style={{
                  display: 'flex',
                  border: 'none',
                  margin: '0 auto',
                  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
                  marginBottom: 50
                }}
              >
                QUERO TESTAR
              </Button>
            </ScrollAnim.Link>
          </Div>
          <Div 
            alignItems={['center']}
          >
            <Hr
              lineColor='lightPeriwinkle'
              m={['0']}
              maxWidth={[328, '100%' ,680 ,680]}
              style={{
                opacity: 0.5
              }}
            />
          </Div>
          <Row
            css={header}
            mt={[40, 45.5, 39.5, 39.5]}
            mb={30}
          >
            <Column>
              <H4 style={{ marginBottom: 16 }}>A escalabilidade da nuvem</H4>
              <Paragraph style={{ marginBottom: 16, textAlign: 'left' }}>VPS tradicionais são uma ótima solução, no entanto pecam quando se trata de escalabilidade de recursos e alta disponibilidade. Em contrapartida, o Cloud Server HostDime possui uma ampla infraestrutura que permite o aumento ou diminuição de ativos computacionais de acordo com a demanda. Uma plataforma elástica que provê e gerencia processamento, memória, largura de banda e armazenamento 100% em SSD automaticamente, sem necessidade de reboot.</Paragraph>
              <Paragraph style={{ textAlign: 'left' }}>Além disso, todo o ambiente do Cloud Server HostDime provê alta disponibilidade, garantindo os recursos necessários para que seus serviços estejam sempre operantes e em funcionamento. Assim, você não fica mais refém de um único hardware, pois quando um falha, automaticamente todas as máquinas virtuais migram para outro hardware disponível, sem indisponibilidade de serviços.</Paragraph>
            </Column>
          </Row>
          <Row
            mt={30}
            mb={60}
            flexDirection={['row']}
          >
            <Div 
              css={table}
            >
              <Column>
                <Div
                  flexDirection={['row']}
                  alignItems={['center']}
                  style={{
                    marginBottom: 24
                  }}
                >
                  <div style={{ marginLeft: 'auto', display: 'flex' }}>
                    <span
                      style={{
                        fontSize: 14,
                        fontWeight: 600,
                        lineHeight: 1.29,
                        textAlign: 'center',
                        color: 'rgba(0, 0, 0, 0.87)',
                        marginRight: 16
                      }}
                    >
                      Cloud Server<br /> HostDime
                    </span>
                    <span
                      style={{
                        fontSize: 14,
                        lineHeight: 1.29,
                        textAlign: 'center',
                        color: 'rgba(0, 0, 0, 0.87)'
                      }}
                    >
                      VPS<br />Tradicional
                    </span>
                  </div>
                </Div>
              </Column>  
              <Column
                width={[1]}
              >
                <Div
                  flexDirection={['row']}
                  alignItems={['center']}
                  style={{
                    marginBottom: 24
                  }}
                >
                  <Subtitle1>Escolha de recursos específicos</Subtitle1>
                  <div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
                    <ThumbUp 
                      style={{
                        color: '#50e3dc'
                      }}
                    />
                    <span css={iconeX}>x</span>
                    <ThumbDown 
                      style={{
                        color: '#d9dee3',
                        marginRight: 32
                      }}
                    />
                  </div>
                </Div>
                <Hr
                  lineColor='lightPeriwinkle'
                  maxWidth={[328, '100%', 680, 680]}
                  style={{
                    opacity: 0.5
                  }}
                />
              </Column>
              <Column
                width={[1]}
                mt={16}
              >
                <Div
                  flexDirection={['row']}
                  alignItems={['center']}
                  style={{
                    marginBottom: 24
                  }}
                >
                  <Subtitle1>Ativação instantânea</Subtitle1>
                  <div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
                    <ThumbUp 
                      style={{
                        color: '#50e3dc'
                      }}
                    />
                    <span css={iconeX}>x</span>
                    <ThumbDown 
                      style={{
                        color: '#d9dee3',
                        marginRight: 32
                      }}
                    />
                  </div>
                </Div>
                <Hr
                  lineColor='lightPeriwinkle'
                  maxWidth={[328, '100%', 680, 680]}
                  style={{
                    opacity: 0.5
                  }}
                />
              </Column>
              <Column
                width={[1]}
                mt={16}
              >
                <Div
                  flexDirection={['row']}
                  alignItems={['center']}
                  style={{
                    marginBottom: 24
                  }}
                >
                  <Subtitle1>Escalabilidade sob demanda</Subtitle1>
                  <div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
                    <ThumbUp 
                      style={{
                        color: '#50e3dc'
                      }}
                    />
                    <span css={iconeX}>x</span>
                    <ThumbDown 
                      style={{
                        color: '#d9dee3',
                        marginRight: 32
                      }}
                    />
                  </div>
                </Div>
                <Hr
                  lineColor='lightPeriwinkle'
                  maxWidth={[328, '100%', 680, 680]}
                  style={{
                    opacity: 0.5
                  }}
                />
              </Column>
              <Column
                width={[1]}
                mt={16}
              >
                <Div
                  flexDirection={['row']}
                  alignItems={['center']}
                  style={{
                    marginBottom: 24
                  }}
                >
                  <Subtitle1>Alta disponibilidade e<br /> balanceamento de carga</Subtitle1>
                  <div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
                    <ThumbUp 
                      style={{
                        color: '#50e3dc'
                      }}
                    />
                    <span css={iconeX}>x</span>
                    <ThumbDown 
                      style={{
                        color: '#d9dee3',
                        marginRight: 32
                      }}
                    />
                  </div>
                </Div>
                <Hr
                  lineColor='lightPeriwinkle'
                  maxWidth={[328, '100%', 680, 680]}
                  style={{
                    opacity: 0.5
                  }}
                />
              </Column>
              <Column
                width={[1]}
                mt={16}
              >
                <Div
                  flexDirection={['row']}
                  alignItems={['center']}
                  style={{
                    marginBottom: 24
                  }}
                >
                  <Subtitle1>Gerenciamento de tráfego</Subtitle1>
                  <div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
                    <ThumbUp 
                      style={{
                        color: '#50e3dc'
                      }}
                    />
                    <span css={iconeX}>x</span>
                    <ThumbDown 
                      style={{
                        color: '#d9dee3',
                        marginRight: 32
                      }}
                    />
                  </div>
                </Div>
                <Hr
                  lineColor='lightPeriwinkle'
                  maxWidth={[328, '100%', 680, 680]}
                  style={{
                    opacity: 0.5
                  }}
                />
              </Column>
              <Column
                width={[1]}
                mt={16}
              >
                <Div
                  flexDirection={['row']}
                  alignItems={['center']}
                  style={{
                    marginBottom: 24
                  }}
                >
                  <Subtitle1>Pague pelo o que usar, quando usar</Subtitle1>
                  <div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
                    <ThumbUp 
                      style={{
                        color: '#50e3dc'
                      }}
                    />
                    <span css={iconeX}>x</span>
                    <ThumbDown 
                      style={{
                        color: '#d9dee3',
                        marginRight: 32
                      }}
                    />
                  </div>
                </Div>
                <Hr
                  lineColor='lightPeriwinkle'
                  maxWidth={[328, '100%', 680, 680]}
                  style={{
                    opacity: 0.5
                  }}
                />
              </Column>
              <Column
                width={[1]}
                mt={16}
              >
                <Div
                  flexDirection={['row']}
                  alignItems={['center']}
                >
                  <Subtitle1>Prevenção avançada contra<br /> ameaças de rede</Subtitle1>
                  <div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
                    <ThumbUp 
                      style={{
                        color: '#50e3dc'
                      }}
                    />
                    <span css={iconeX}>x</span>
                    <ThumbDown 
                      style={{
                        color: '#d9dee3',
                        marginRight: 32
                      }}
                    />
                  </div>
                </Div>
              </Column>
            </Div>
          </Row>
          <Div
            css={steptCloudItem}
          >
            <Button
              onClick={() => navigate('/precos-cloud-server')}
              schema='secondary'
              color='white'
              variant='highEmphasis'
              width={['100%']}
              maxWidth={['328px']}
              style={{
                display: 'flex',
                border: 'none',
                margin: '0 auto',
                boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
                marginBottom: 50
              }}
            >
              VER PREÇOS E PLANOS
            </Button>
            <Div 
              alignItems={['center']}
            >
              <Hr
                lineColor='lightPeriwinkle'
                maxWidth={[328, '100%' ,680 ,680]}
                style={{
                  opacity: 0.5
                }}
              />
            </Div>
          </Div>
          <EnhancePriceForm
            setModalVisibility={setModalVisibility}
          />
        </Container>
      </section>
      <section css={faqCloudServer}>
        <Container>
          <Row
            mt={40}
            mb={40}
            justifyContent='center'
          >
            <H4>Tire suas Dúvidas</H4>
          </Row>
          <Row
            mb={50}
          >
            <Div
              maxWidth={['100%', 422, 422]}
            >
              <Solution
                title='Por que eu tenho que fazer um depósito para obter uma conta do Cloud Server HostDime?'
                id='solution01'
              >
                O serviço de Cloud da HostDime utiliza um sistema de dedução de crédito. Dessa forma, você precisa adicionar créditos ao sistema para fazer utilização do serviço. Você pode adicionar a partir de R$ 75,00 (valor mínimo). Uma vez que a cobrança é efetuada por hora, o sistema registrará sua utilização por hora e deduzirá esse valor de seus créditos. 
              </Solution>
              <Solution
                title='Preciso monitorar constantemente meu saldo de crédito e recarregar manualmente minha conta?'
                id='solution02'
              >
                Você pode configurar em seu painel do cliente para que o sistema emita um alerta quando o saldo estiver abaixo do limite definido. Dessa forma, uma fatura de recarga será gerada automaticamente. 
              </Solution>
              <Solution
                title='Que tipos de pagamentos vocês aceitam?'
                id='solution12'
              >
                São aceitos os métodos de pagamento definidos nos termos de serviço: cartão de crédito, PayPal, boleto bancário e Bitcoin.
              </Solution>
              <Solution
                title='Como sou cobrado pelas minhas instâncias?'
                id='solution03'
              >
                Todo o faturamento é feito a cada hora. 
              </Solution>
              <Solution
                title='Sou cobrado por instâncias paradas?'
                id='solution04'
              >
                Instâncias paradas são cobradas apenas pelo espaço em disco alocado. 
              </Solution>
              <Solution
                title='Por que minha estimativa mensal é diferente do valor da minha cobrança real?'
                id='solution05'
              >
                A estimativa mensal que exibimos é baseada em 730h/mês. Alguns meses têm mais de 730 horas, outros têm menos. 
              </Solution>
              <Solution
                title='O crédito da HostDime é diferente do crédito da HostDime Cloud?'
                id='solution06'
              >
                Sim, os créditos em nuvem só podem ser usados para o(s) produto(s) da nuvem, O crédito do HostDime Client pode ser usado para comprar crédito da HostDime Cloud.
              </Solution>
              <Solution
                title='Posso converter o crédito do HostDime em crédito no HostDime Cloud?'
                id='solution07'
              >
                Você pode gerar uma fatura para o crédito da HostDime Cloud e usar o crédito para pagar essa fatura. Não há nenhum botão de “converter” atualmente. 
              </Solution>
              <Solution
                title='Como é faturada a utilização de banda nas instâncias?'
                id='solution08'
              >
                No DC Orlando, toda nova instância recebe gratuitamente 10TB de banda. No DC Brasil, novas instâncias recebem gratuitamente 10 TB de tráfego outbound e 5 TB de tráfego inbound. 
              </Solution>
              <Solution
                title='Qual o valor da largura de banda excedente, caso eu ultrapasse os 10 TB?'
                id='solution09'
              >
                No DC Orlando, a banda inbound é free e a banda outbound custa R$ 0,04 por GB excedente. No DC João Pessoa, o GB de banda inbound excedente custa R$ 0,08, e o GB de banda outbound é R$ 0,04.
              </Solution>
              <Solution
                title='Como entrar no meu portal HostDime Cloud diretamente?'
                id='solution10'
              >
                Você só pode acessar seu portal do HostDime Cloud através do CORE, no seguinte endereço: https://core.hostdime.com.br/cloud/
              </Solution>
              <Solution
                title='Vocês oferecem o IPv6?'
                id='solution11'
              >
                Nós oferecemos um prefixo /64 por cliente, livre de custos. O IPv6 é atualmente um produto não-SLA e todos os clientes devem reconhecer especificamente isso por meio de nosso suporte técnico antes da atribuição de sub-rede.
              </Solution>            
            </Div>
          </Row>
        </Container>
      </section>
      <section css={styledCloudServer} id='quero-testar'>
        <Container>
          <Row
            mt={40}
            pb={50}
            display='flex'
            justifyContent='center'
            textAlign='center'
          >
            <Column>
              <div id='form-discover' style={{ height: 1 }} />
              <H5>Descubra um ambiente <br />na nuvem com <br />gerenciamento inteligente.</H5>
              <Subtitle>Experimente a recarga mínima com 50% off</Subtitle>
              <Div
                style={{
                  marginTop: 40,
                  marginBottom: 36,
                  textAlign: 'center'
                }}
              >
                <CloudServerForm
                  fields={fields}
                  formId={33}
                  submitText='Solicitar meu cupom'
                />
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
      <UnderstandTheDifference
        visible={modals.moreInfo}
        onClose={() => setModalVisibility('moreInfo')(false)}
      />
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => {
  return (
    <Layout>
      <Seo title='Cloud Server Hostdime' />
      <PageTemplate
        title='Cloud Server Hostdime'
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cloud-server/cloud-server.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const enhance = compose(
  withSolutions
)

export default enhance(StaticPage)
